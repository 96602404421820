import React from "react"
import BannerBars from '../icons/banner_bars'

const MentorCard = (props) => {
  var {mentor, barsUrl} = props
  const handleMentorClick = ()=>{
    props.handleMentorClick(mentor)
  }
  const eng = props.language==="english"
  var image = mentor.profilePic && mentor.profilePic.localFile ? mentor.profilePic.localFile.publicURL : ""
  return(
    <div className="small-mentor-card tall hover-move">
      <BannerBars />
      <div>
        <div style={{display:image.includes("Placeholder_Mentor_Small")?"none":"block"}}/>
        <img src={image}/>
      </div>
      <div>
        <p>{mentor.name}</p>
        <p>{mentor.company}</p>
      </div>
      <div
        role="text" 
        className="industry-label" 
        style={{
          marginTop:mentor.company?"0":"20px",
          color:mentor.industry.labelTextColor, 
          backgroundColor:mentor.industry.labelColor
        }}>
        {eng?mentor.industry.name:mentor.industry.nameFrench}
      </div>
      <div/>
      <button onClick={handleMentorClick} className="common-button">{eng?"View Mentor":"Voir le mentor"}</button>
    </div>
  )
}

export default MentorCard