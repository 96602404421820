import React, {useEffect} from "react"
import CancelIcon from '../global/cancel_icon'
import BannerBars from '../icons/banner_bars'
import LinkedinIcon from '../icons/linkedin_icon'

const MentorModal = (props) => {
  const {mentor, active} = props
  const eng = props.language==="english"
  useEffect(()=>{  
    if(active){
      document.querySelector("html").style.overflow ="hidden"
    } else {
      document.querySelector("html").style.overflow ="auto"
    }
  }, [active])
  var profileImage = mentor.profilePic && mentor.profilePic.localFile ? mentor.profilePic.localFile.publicURL : ""
  var modalImage = mentor.modalPic && mentor.modalPic.localFile ? mentor.modalPic.localFile.publicURL : ""
  return(
    <div className={`modal-container ${active&&"active"}`}>
      {mentor&&<div className={`mentor-modal ${active&&"active"}`}>
        <div>
          <BannerBars />
          <img src={profileImage} className="mentor-modal-image-mobile" />
          <div style={{position:"relative"}}>
            <h1 className="page-title left">{mentor.name}</h1>
            <p style={{fontFamily:"Rift", fontSize:"18px"}} >{mentor.company}</p>
            <div className="industry-label" style={{color:mentor.industry.labelTextColor, backgroundColor:mentor.industry.labelColor}}>
              {eng?mentor.industry.name:mentor.industry.nameFrench}
            </div>
          </div>
          <div>
            <h3 style={{marginTop:mentor.company?"0":"50px"}}>{eng?"About":"Info"}</h3>
            <p>{eng?mentor.bio:mentor.bioFrench}</p>
          </div>
          <div>
            <h3>Mantra</h3>
            <p>{eng?mentor.Mantra:mentor.mantraFrench}</p>
          </div>
          {mentor.linkedInUrl !== "n/a" && <a target="_blank" href={mentor.linkedInUrl}><LinkedinIcon /></a>}
        </div>
        <div style={{backgroundImage:`url(${modalImage})`}}>
        </div>
        <button onClick={props.clearModalActive}><CancelIcon /></button>
      </div>}
    </div>
  )
}

export default MentorModal