import React from 'react'

class BannerBars extends React.Component {
  render() {
    return (
      <svg
        style={this.props.svgStyles}
        // height={this.props.height}
        // width={this.props.width}
        // style={this.props.svgStyles}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 276.02 383.13"
        className={this.props.className}
        aria-hidden="true"
      >
       <polygon fill="#fff" points="0.35 0.1 250.94 0.1 59.25 383.31 0.35 383.31 0.35 0.1"/>
       <polygon fill="#2fa849" points="218.42 0.35 275.19 0.35 87.32 383.06 28.48 383.06 218.42 0.35"/>
       <polygon fill="#dca827" points="93 82.94 148.74 82.94 58.42 274.94 1.13 274.94 93 82.94"/>
      </svg>
    );
  }
}
BannerBars.defaultProps = {
  // height: "15px",
  svgStyles: {pointerEvents:"none"},
  // width: "15px",
};
export default BannerBars